import EmptyState from '@aurora/shared-client/components/common/EmptyState/EmptyState';
import { ListVariant } from '@aurora/shared-client/components/common/List/enums';
import { PagerVariant } from '@aurora/shared-client/components/common/Pager/enums';
import { PanelType } from '@aurora/shared-client/components/common/Panel/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import type {
  EndUserRouteAndParams,
  MessagePagesAndParams
} from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  MessageConstraints,
  MessageSorts,
  IdeaStatusDetails
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ConversationStyle,
  OccasionStatusFilterType,
  OccasionType,
  SortDirection
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { EndUserPages } from '@aurora/shared-types/pages/enums';
import { EndUserComponent, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { TextAlignment } from '@aurora/shared-types/texts/enums';
import {
  enumFromValue,
  isObjectEmpty,
  merge,
  UndefinedValueMergeBehavior
} from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import React, { useCallback, useContext, useEffect } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { buildMessagesConstraintsForNode } from '../../../helpers/nodes/NodeHelper/NodeHelper';
import { ItemType, MessageTimestamp, MessageViewVariant } from '../../../types/enums';
import type {
  MessageViewFragment,
  MessageViewsQuery,
  MessageViewsQueryVariables
} from '../../../types/graphql-types';
import PaneledItemList from '../../common/List/PaneledItemList/PaneledItemList';
import PaneledItemListSubHeader from '../../common/List/PaneledItemListSubHeader/PaneledItemListSubHeader';
import type { OverflowSetItem } from '../../common/OverflowSet/OverflowSet';
import EditableWidget from '../../common/Widget/EditableWidget';
import type { WidgetFC } from '../../common/Widget/types';
import type { ItemViewTypeAndProps } from '../../entities/types';
import MessagesWidgetFilters from '../../common/MessagesWidgetFilters/MessagesWidgetFilters';
import MessageListTabs, {
  MessageListTabItem
} from '../../messages/MessageListTabs/MessageListTabs';
import messagesQuery from '../../messages/MessageViews.query.graphql';
import useEntityViewQuery from '../../useEntityViewQuery';
import useQueryParamArrayForWidget from '../../useQueryParamArrayForWidget';
import useQueryParamValueForWidget from '../../useQueryParamValueForWidget';
import useTranslation from '../../useTranslation';
import type { OccasionListForNodeWidgetProps } from '../OccasionListForNodeWidgetEditor/types';
import OccasionStatusFilterDropdown from '../OccasionStatusFilter/OccasionStatusFilterDropdown';
import localStyles from './OccasionListForNodeWidget.module.pcss';

const defaultProps: OccasionListForNodeWidgetProps = {
  style: 'list',
  viewVariant: {
    type: MessageViewVariant.INLINE,
    props: {
      useBoardIcon: false,
      useBody: true,
      useNode: false,
      useNodeLink: true,
      useTags: true,
      useKudosCount: true,
      useAttendeeCount: true,
      useViewCount: false,
      useUnreadCount: false,
      useRepliesCount: true,
      useAvatar: false,
      clampSubjectLines: 1,
      portraitClampBodyLines: 3,
      useAuthorLogin: false,
      useAuthorLoginLink: true,
      useAuthorRank: false,
      useSolvedBadge: false,
      usePreviewMedia: true,
      timeStampType: null,
      useVideoPreview: false,
      useOccasionTime: true,
      useOccasionDateIcon: true,
      useAttendanceIndicator: true,
      useTextBody: true,
      useOccasionType: true,
      useMessageTimeLink: true,
      useOccasionBadge: true
    }
  },
  listVariant: {
    type: ListVariant.LIST_GROUP
  },
  pagerVariant: {
    type: PagerVariant.LOAD_MORE
  },
  pageSize: 10,
  useTitle: true,
  hideIfEmpty: false,
  panelType: PanelType.DIVIDER,
  lazyLoad: false,
  enablePagination: true,
  useOccasionFilterBar: true,
  filterOrSortToBeApplied: {
    key: OccasionStatusFilterType.Upcoming,
    value: OccasionStatusFilterType.Upcoming,
    groupKey: 'group'
  },
  detailedListTabs: [
    {
      key: OccasionStatusFilterType.Upcoming,
      value: OccasionStatusFilterType.Upcoming,
      groupKey: 'group'
    }
  ]
};

export function getFinalProps(
  props: OccasionListForNodeWidgetProps
): OccasionListForNodeWidgetProps {
  return merge(defaultProps, props, {
    undefinedMergeBehavior: UndefinedValueMergeBehavior.IGNORE_BEFORE_MERGE,
    mergeNested: false
  });
}

const messageSortsMap: Record<string, MessageSorts> = {
  [MessageListTabItem.MOST_RECENT]: {
    conversationLastPostingActivityTime: { direction: SortDirection.Desc }
  },
  [MessageListTabItem.MOST_VIEWED]: { viewsCount: { direction: SortDirection.Desc } },
  [MessageListTabItem.MOST_KUDOED]: { kudosSumWeight: { direction: SortDirection.Desc } },
  [MessageListTabItem.NEWEST]: { postTime: { direction: SortDirection.Desc } }
};
/**
 * A node widget that displays occasions of a given node.
 *
 * @constructor
 *
 * @author Aditi Agarwal
 */
const OccasionListForNodeWidget: WidgetFC<OccasionListForNodeWidgetProps> = props => {
  const { isVisible, ...rest } = props;
  const finalProps: OccasionListForNodeWidgetProps = getFinalProps(rest);
  const { key: statusParameterKey, value: statusParameterValue } = useQueryParamValueForWidget(
    EndUserQueryParams.SEARCH_FILTER_BY_MESSAGE_STATUS,
    'ALL'
  );
  const { key: tagsParameterKey, value: tagsParameterValue } = useQueryParamArrayForWidget(
    EndUserQueryParams.SEARCH_BY_TAGS_FILTER,
    []
  );
  const {
    style,
    viewVariant,
    listVariant,
    pagerVariant,
    className,
    pageSize,
    useTitle,
    hideIfEmpty,
    panelType,
    enablePagination,
    textAlignment,
    useOccasionFilterBar,
    filterOrSortToBeApplied,
    detailedListTabs
  } = finalProps;
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.OCCASION_LIST_FOR_NODE_WIDGET
  );
  const { contextNode } = useContext(AppContext);
  const { occasionsEnabled } = useContext(TenantContext)?.publicConfig;
  const { router } = useEndUserRoutes();
  let constraints: MessageConstraints = buildMessagesConstraintsForNode(contextNode);
  constraints.depth = { eq: 0 };
  constraints.conversationStyle = { in: [ConversationStyle.Occasion] };

  const tabsMap = {};
  detailedListTabs.forEach(tab => {
    if (tab.groupKey === 'sort') {
      tabsMap[tab.key] = { key: tab.key, group: 'sort' };
    } else {
      tabsMap[tab.value] = {
        key: [
          tab.value,
          ...(tab.value === OccasionStatusFilterType.Upcoming
            ? [OccasionStatusFilterType.Ongoing]
            : [])
        ],
        group: 'group'
      };
    }
  });
  const tabsList = Object.keys(tabsMap) as MessageListTabItem[];
  const useEmpty = !hideIfEmpty || tabsList.length > 1;

  const [firstTab] = tabsList;
  const { key: queryParamKey, value: queryParamValue } = useQueryParamValueForWidget(
    'tab',
    firstTab
  );

  const activeTab = queryParamValue;

  let sorts: MessageSorts = null;

  if (style !== 'list') {
    sorts =
      filterOrSortToBeApplied.groupKey === 'sort'
        ? messageSortsMap[filterOrSortToBeApplied.key]
        : {};
  } else {
    sorts = tabsMap[activeTab].group === 'sort' ? messageSortsMap[activeTab] : {};
  }

  if (isObjectEmpty(sorts)) {
    if (
      (style === 'list' && activeTab === 'PAST') ||
      ((style === 'compact' || style === 'card') && filterOrSortToBeApplied.key === 'PAST')
    ) {
      sorts = { occasionStartTime: { direction: SortDirection.Desc } };
    } else {
      sorts = { occasionStartTime: { direction: SortDirection.Asc } };
    }
  }

  let eventStatusConstraint: MessageConstraints = null;

  if (style !== 'list') {
    eventStatusConstraint =
      filterOrSortToBeApplied.groupKey === 'group'
        ? {
            occasionStatus: {
              in: [
                filterOrSortToBeApplied.key as OccasionStatusFilterType,
                ...(filterOrSortToBeApplied.key === OccasionStatusFilterType.Upcoming
                  ? [OccasionStatusFilterType.Ongoing]
                  : [])
              ]
            }
          }
        : null;
  } else {
    eventStatusConstraint =
      tabsMap[activeTab].group === 'group'
        ? { occasionStatus: { in: tabsMap[activeTab].key } }
        : null;
  }

  constraints = {
    ...constraints,
    ...eventStatusConstraint,
    ...(tagsParameterValue &&
      tagsParameterValue.length > 0 && { tagsText: { in: tagsParameterValue } }),
    ...(statusParameterValue &&
      statusParameterValue.length > 0 && {
        occasionType: { eq: enumFromValue(OccasionType, statusParameterValue) }
      })
  };

  const variables = {
    constraints,
    sorts,
    useOccasionData: true,
    first: pageSize
  };

  const commonProps = {
    ...viewVariant.props,
    ...(viewVariant.props.timeStampType && {
      timeStampType: sorts?.conversationLastPostingActivityTime
        ? MessageTimestamp.LAST_POSTING_ACTIVITY_TIME
        : MessageTimestamp.POST_TIME
    })
  };

  const cardViewVariant = {
    type: viewVariant.type,
    props: {
      ...commonProps,
      textAlignment: textAlignment
    }
  };

  const listViewVariant = {
    type: viewVariant.type,
    props: commonProps
  };

  const queryResult = useEntityViewQuery<
    ItemType.MESSAGE,
    MessageViewsQuery,
    MessageViewsQueryVariables
  >(module, ItemType.MESSAGE, viewVariant, messagesQuery, {
    variables
  });

  const { data: queryData, loading } = queryResult;
  useEffect(() => {
    if (!useEmpty && !loading) {
      isVisible(queryData?.messages?.edges?.length !== 0);
    }
  }, [queryData, isVisible, loading, useEmpty]);

  /**
   * callback to handle status filter change
   */
  const onStatusFilterChange = useCallback(
    async (item: IdeaStatusDetails) => {
      await router.replaceRoute<EndUserRouteAndParams<EndUserPages>>(
        router.getCurrentPageName(),
        router.getPathParams<MessagePagesAndParams>(),
        {
          ...router.getQueryParams(),
          [statusParameterKey]: item.statusKey
        },
        { scroll: false }
      );
    },
    [router, statusParameterKey]
  );

  if (textLoading || !occasionsEnabled) {
    return null;
  }

  /**
   * Function to render tabbed filters
   */
  function renderTabList(): React.ReactElement {
    if (style === 'list' && tabsList.length > 1) {
      return (
        <PaneledItemListSubHeader panel={panelType} as={'nav'}>
          <MessageListTabs
            activeTab={activeTab as MessageListTabItem}
            items={tabsList}
            queryParamKey={queryParamKey}
            recordHistory={false}
            conversationStyle={ConversationStyle.Occasion}
            useBottomBorder={panelType === PanelType.STANDARD}
          />
        </PaneledItemListSubHeader>
      );
    }

    return null;
  }

  /**
   * Set of Additional filter items
   */
  const occasionFiltersItems: OverflowSetItem[] = [
    {
      key: 'occasionMessagesStatusFilter',
      onRender: (
        <OccasionStatusFilterDropdown
          onChange={onStatusFilterChange}
          selectedStatus={statusParameterValue}
          occasionStatusTab={tabsMap[activeTab].group === 'group' ? activeTab : undefined}
        />
      )
    }
  ];

  /**
   * Function to render the filter bar
   */
  function renderFilterBar(): React.ReactElement {
    if (style === 'list' && activeTab !== null && useOccasionFilterBar === true) {
      return (
        <PaneledItemListSubHeader
          panel={panelType}
          as={'nav'}
          className={cx({ 'lia-bubble': panelType === PanelType.BUBBLE })}
        >
          <MessagesWidgetFilters
            tagsParameterKey={tagsParameterKey}
            selectedTags={tagsParameterValue}
            nodeId={contextNode.id}
            className={cx(
              { 'lia-g-py-10': panelType !== PanelType.BUBBLE },
              { 'lia-g-pt-0': panelType === PanelType.BUBBLE }
            )}
            additionalFilters={occasionFiltersItems}
          />
        </PaneledItemListSubHeader>
      );
    }

    return null;
  }

  function renderSubHeaders(): React.ReactElement {
    return (
      <>
        {renderTabList()}
        {renderFilterBar()}
      </>
    );
  }

  /**
   * Function to render header
   */
  function renderHeader(): React.ReactElement {
    if (useTitle) {
      return <h3 className={cx('text-break mb-0 h5')}>{formatMessage('title')}</h3>;
    }
  }

  /**
   * A callback function to render Empty State when entity list has no occasions
   */
  function renderNoPosts(): React.ReactElement {
    return (
      <EmptyState description={formatMessage('emptyDescription')} alignment={TextAlignment.LEFT} />
    );
  }

  return (
    <EditableWidget<OccasionListForNodeWidgetProps> props={finalProps}>
      <PaneledItemList<
        MessageViewFragment,
        ItemType.MESSAGE,
        ItemViewTypeAndProps<ItemType.MESSAGE, MessageViewVariant>,
        MessageViewsQuery,
        MessageViewsQueryVariables
      >
        type={ItemType.MESSAGE}
        variant={style === 'card' ? cardViewVariant : listViewVariant}
        listVariant={listVariant}
        queryResult={queryResult}
        headerClassName={cx('text-break mb-0')}
        bodyClassName="lia-g-mb-0"
        header={useTitle && renderHeader}
        subHeader={renderSubHeaders}
        panel={panelType}
        pagerVariant={pagerVariant}
        pageSize={pageSize}
        className={cx(className)}
        empty={renderNoPosts}
        useEmpty={useEmpty}
        footerClassName={cx({ 'lia-g-mt-5 bg-transparent': style === 'card' })}
        useSubHeaderWhenEmpty={
          style === 'list' && (useOccasionFilterBar === true || activeTab !== null)
        }
        useFooter={enablePagination && pagerVariant.type !== PagerVariant.NONE}
        role="tabpanel"
      />
    </EditableWidget>
  );
};

export default OccasionListForNodeWidget;
